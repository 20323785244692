import React from "react";
//import HeaderOne from "./header/HeaderOne";
import HeaderTwoImpressum from "./header/HeaderTwoImpressum";
//import HeaderTopNews from "./header/HeaderTopNews";
//import FooterTwo from "./footer/FooterTwo";
//import Copyright from "./footer/Copyright";

const Layout = ({ children }) => {
    return (
        <>
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                {/* <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" /> */}
                <HeaderTwoImpressum btnStyle="btn-small" HeaderSTyle="header-transparent" />
                {children}
                {/* <FooterTwo />
                <Copyright /> */}
            </main>
        </>
    );
};
export default Layout;
