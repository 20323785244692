import React from "react";

const BrandList = [
    {
        image: "./images/embres/yocto.png",
    },
    {
        image: "./images/embres/jenkins.png",
    },
    {
        image: "./images/embres/react.png",
    },
    {
        image: "./images/embres/flutter.png",
    },
    {
        image: "./images/embres/qt.png",
    },
    {
        image: "./images/embres/allegro.png",
    },
    {
        image: "./images/embres/altium.png",
    },
    {
        image: "./images/embres/eagle.png",
    },
    {
        image: "./images/embres/linux.png",
    },
    {
        image: "./images/embres/android.png",
    },
    {
        image: "./images/embres/grafana.png",
    },
    {
        image: "./images/embres/xilinx.png",
    },
];

const BrandOne = ({ brandStyle }) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <img src={`${data.image}`} alt="Brand Image" />
                </li>
            ))}
        </ul>
    );
};

export default BrandOne;
