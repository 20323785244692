import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabOne = () => {
    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <Tabs>
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-5">
                                <img className="radius-small" src="./images/embres/box2.png" alt="Corporate React Template" />
                            </div>
                            <div className="col-lg-7 mt_md--40 mt_sm--40">
                                <div className="rn-default-tab">
                                    <div className="tab-button-panel">
                                        <TabList className="tab-button">
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>unsere Erfahrung</button>
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>Kunden Reporting</button>
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>Agiles Engineering</button>
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>Tooling</button>
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>unser Team</button>
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>Historie</button>
                                                </div>
                                            </Tab>
                                        </TabList>
                                    </div>

                                    <div className="tab-content-panel">
                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <p>Wir meinen Hardware und Software gehören zusammen.</p>

                                                    <p>
                                                        Diese Erkenntnis hat sich in unseren 15 jährigen Projektarbeiten immer wieder bestätigt. Wir können
                                                        unseren Kunden eine umfassende sowie partielle Produkt- bzw. Komponentenentwicklung bieten.
                                                    </p>

                                                    <p>
                                                        Ob wir für Sie nur definierte Komponenten in einem größeren System erstellen, oder Sie von der Idee über
                                                        Prototypen, bis zum Serienprodukt unterstützen dürfen, liegt alleine an Ihren Anforderungen.
                                                    </p>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <p>Wir halten während der Projektphase stetigen Kontakt mit unseren Kunden.</p>

                                                    <p>
                                                        Das Reporting über die aktuellen Zwischenstände des Projektes ist wichtiger Bestandteil einer
                                                        Entwicklung.
                                                    </p>
                                                    <p>
                                                        Wir wissen wie sich die Anforderungen an elektrischen und mechanischen Komponenten während der
                                                        Entwicklungsphase dynamisch ändern können. Daher sind Änderungen von Anforderungen kein Hinderniss,
                                                        sondern eine Notwendigkeit um für Sie das bestmögliche Produkt zu entwickeln.
                                                    </p>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <p>Wir arbeiten agil - schon immer.</p>

                                                    <p>
                                                        Durch das agile Engineering erreichen wir schnelle Prototypen, zeitnahes Kundenfeedback und eine
                                                        optimierte parallele Entwicklung von Hard- und Software. Unsere Kunden sind durch die zeitnahe
                                                        Bereitstellung von Prototypen in der Lage, ihre Systemintegration zu beschleunigen. Dadurch können
                                                        wichtige Änderungen während der Entwicklung in das zu erstellende Produkt einfließen. Durch das agile
                                                        Engineering werden Zeitstrecken verkürzt und die Entwicklung transparent gehalten.
                                                    </p>
                                                    <p></p>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <p>Wir erstellen Ihre Produkte mit den neuesten Technologien.</p>

                                                    <p>
                                                        Unsere Ingenieure erstellen die Fertigungsunterlagen mit modernsten PCB-Design und CAD-Systemen. Auf
                                                        Wunsch passen wir uns Ihrer internen Entwicklung an. Nach Ihren Anforderungen verwenden wir Ihre
                                                        bevorzugte Software. Die erstellten Fertigungsdaten, sowie die PCB-Design-Projekte und konstruktive
                                                        Zeichnungen werden selbstverständlich vollständig an den Kunden übergeben.
                                                    </p>
                                                    <p>Damit ist eine spätere interne Weiterentwicklung beim Auftraggeber sichergestellt.</p>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <p>Wir haben einen festen Kern an hochmotivierten Ingenieuren und Mitarbeitern.</p>

                                                    <p>
                                                        Wir legen maximalen Wert auf die Motivation der Mitarbeiter in unserem Team. Ein flexibles
                                                        Arbeitszeitmodell gehört genauso dazu, wie der hohe Grad an Transparenz in unserem Handeln. Dadurch ist
                                                        es unseren Mitarbeitern möglich, sich bestmöglich auf die Projekte zu fokussieren. Unsere Entwickler
                                                        werden von allen störenden administrativen Zwängen freigehalten.
                                                    </p>
                                                    <p>Wir haben Motivation, Begeisterung und Leidenschaft für Ihre Projekte.</p>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <p>
                                                        <br />
                                                        2005 Gründung von ERC Sülzer - Ingenieurbüro
                                                    </p>

                                                    <p>
                                                        <br />
                                                        2013 Neubau von Geschäftsräumen in Köln
                                                        <br />
                                                        &nbsp;
                                                    </p>

                                                    <p>
                                                        2017 Gründung der embres GmbH und Ausweitung der angebotenen Dienstleistungen
                                                        <br />
                                                        &nbsp;
                                                    </p>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default TabOne;
