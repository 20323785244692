import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
    static defaultProps = {
        center: {
            lat: 59.025,
            lng: 6.883,
        },
        zoom: 11,
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div className="google-map-style-1">
                <GoogleMapReact
                    // bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent lat={59.025} lng={6.883} text="embres GmbH" />
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMapStyle;
