import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import DigitalAgency from "./pages/DigitalAgency";
import Impressum from "./pages/Impressum";

// Import Css Here
import "./assets/scss/style.scss";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const App = () => {
    const { trackPageView } = useMatomo();
    // Track page view
    React.useEffect(() => {
        trackPageView();
    }, []);

    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={DigitalAgency} />
                    <Route path={`${process.env.PUBLIC_URL + "/impressum"}`} exact component={Impressum} />
                </Switch>
            </PageScrollTop>
        </Router>
    );
};

export default App;
