import React from "react";
import ContactOne from "../elements/contact/ContactOne";
import TabOne from "../elements/tab/TabOne";
import GalleryOne from "../elements/gallery/GalleryOne";
import BrandOne from "../elements/brand/BrandOne";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
/* import { Link } from "react-router-dom";
import Copyright from "../common/footer/Copyright";
import FooterOne from "../common/footer/FooterOne";
import FooterTwo from "../common/footer/FooterTwo";
import FooterThree from "../common/footer/FooterThree"; */
import FooterFour from "../common/footer/FooterFour";

import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
// import Separator from "../elements/separator/Separator";
import ServiceFive from "../elements/service/ServiceFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
/* import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CircleProgress from "../elements/progressbar/CircleProgress";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList"; */
// import BlogClassicData from "../data/blog/BlogList.json";
// import Contact from "../elements/contact/Contact";

// var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
    {
        image: "/images/embres/bg-pcb.jpg",
        title: "embedded systems",
        description: "Wir unterstützen unsere Kunden bei der Entwicklung von Spitzentechnologie.",
    },
    /* {
        image: "/images/bg/bg-image-14.jpg",
        title: "Creative Agency.",
        description: "We help our clients succeed by creating brand identities, digital experiences, and print materials.",
    },
    {
        image: "/images/bg/bg-image-15.jpg",
        title: "Startup Agency.",
        description: "We help our clients succeed by creating brand identities, digital experiences, and print materials.",
    }, */
];

const PopupData2 = [
    {
        id: "01",
        image: "./images/embres/b5_2.jpg",
    },
    {
        id: "02",
        image: "./images/embres/b1_1.jpg",
    },
    {
        id: "03",
        image: "./images/embres/b25_2.jpg",
    },
    {
        id: "04",
        image: "./images/embres/b10_1.jpg",
    },
    {
        id: "05",
        image: "./images/embres/b15.jpg",
    },
    {
        id: "06",
        image: "./images/embres/b24_1.jpg",
    },
    {
        id: "07",
        image: "./images/embres/b16_1.jpg",
    },
    {
        id: "08",
        image: "./images/embres/b9_1.jpg",
    },
];

const DigitalAgency = () => {
    return (
        <>
            <SEO title="embres GmbH" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})` }}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-7">
                                            <div className="inner text-start">
                                                <h1 className="title" dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                                {/* <div className="button-group mt--30">
                                                    <a
                                                        className="btn-default"
                                                        target="_blank"
                                                        href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                                                    >
                                                        PURCHASE NOW
                                                    </a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Unsere Dienstleistungen"
                                    title="Hard- und Software Entwicklung"
                                    description="Unabhängig davon, ob Sie eine kostenoptimierte uC-Steuerung, Linux-Device-Treiber oder eine kundenspezifische Produkt-Plattform benötigen, wir unterstützen Sie bei der Entwicklung Ihrer Komponenten und Produkte."
                                />
                            </div>
                        </div>
                        <ServiceFive serviceStyle="gallery-style" textAlign="text-start" />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* <Separator />
                <div className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Why Choose Us"
                                    title="Companies Choose us!"
                                    description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay" />
                    </div>
                </div> */}

                {/* <Separator />
                <div className="rwt-progressbar-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Expertise."
                                    title="Compnanies Expertise."
                                    description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <CircleProgress />
                        </div>
                    </div>
                </div> */}

                {/* <Separator /> */}
                {/* Start Testimonial Area  */}
                {/* <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Client Feedback"
                                    title="What People Are Saying."
                                    description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one style-two" />
                    </div>
                </div> */}
                {/* End Testimonial Area  */}

                {/* <Separator />
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Get The Latest News"
                                    title="What Our Trending News."
                                    description="We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* <FooterFour /> */}

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap" id="thinking">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Informationen"
                                    title="thinking outside the box"
                                    description=""
                                />
                            </div>
                        </div>
                        <TabOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-gallery-area rn-section-gap" id="projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--20">
                                <SectionTitle textAlign="text-center" radiusRounded="" subtitle="Referenz-Projekte" title="" description="" />
                            </div>
                        </div>
                        <div className="row mt_dec--30 row--15">
                            {PopupData2.map((item) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gap" id="brand">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="unser Tooling"
                                    description="Auszug aus unseren vewendeten Tools"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                                <BrandOne brandStyle="brand-style-1" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* <Contact /> */}
                <div className="main-content" id="contact">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle textAlign="text-center" radiusRounded="" subtitle="Kontakt" title="" description="" />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
                <FooterFour />
            </main>
        </>
    );
};
export default DigitalAgency;
